import * as React from "react"
import { PageProps } from "gatsby"

import Layout from "../../components/Layout"
import SEO from "../../components/Seo"
import SubHero from "../../components/SubHero"
import CaseStudyContent from "../../sections/case-study/CaseStudyContent"

import caseStudyBgUrl from "../../../media/case-study-banner-overlay.jpg"

import { insuranceHoldingCompanyContentList } from "../../sections/case-study/data"

const caseStudyTitle = "Insurance Holding Company leverages full suite of Grandview services to achieve their target operating model"
const caseStudyDescription = ""

const insuranceHoldingCompanyCaseStudy = ({ location }: PageProps) => (
    <Layout location={location}>
        <SEO title={caseStudyTitle} />
        <SubHero
            backgroundImage={caseStudyBgUrl}
            title={caseStudyTitle}
            description={caseStudyDescription}
        />
        <CaseStudyContent content={insuranceHoldingCompanyContentList} />
    </Layout>
)

export default insuranceHoldingCompanyCaseStudy
